import { HomepageWidget, type ProgramConfig } from './types';

const nwd: ProgramConfig = {
  // NB. This config file currently uses Upliv program ID, slug and some other
  // identifiers to facilitate rendering upcoming Northwell look and feel against
  // existing Upliv data. We will update this when Northwell data becomes a distinct
  // entity in the platform fixtures.
  programId: '6f3fd233-53f4-3e1d-b38e-3083917b67d8',
  programSlug: 'upliv',
  programName: 'Northwell Direct',
  contentfulTag: 'platformUpliv',

  privacyPolicyUrl: 'https://www.northwell.edu/privacy-policies-disclaimers',
  termsOfServiceUrl: 'https://www.northwell.edu/terms-of-service',
  welkinCloudLink: '',

  noticeOfPrivacyPracticesUrl: '',
  telehealthConsentUrl: '',

  supportPhoneNumber: '1234567890',
  supportEmail: 'support@example.com',
  supportFaqUrl: 'https://www.northwell.edu/help',

  homepage: [
    {
      component: HomepageWidget.WelcomeWidget,
    },
    {
      component: HomepageWidget.FindCareCard,
    },
    {
      component: HomepageWidget.PreventativeToDoList,
    },
    {
      component: HomepageWidget.OtherWorkBenefits,
    },
  ],
};

export default nwd;
