import type { PropsWithChildren, FunctionComponent } from 'react';
import cx from 'clsx';
import CaireFont from '@/components/CaireFont';
import { useTenantConfig } from '@/contexts/TenantConfig';

type CaireStyleProps = PropsWithChildren &
  (
    | {
        enableTenantTheme: true;
        theme?: never;
      }
    | {
        theme?: string;
        enableTenantTheme?: false | undefined;
      }
  );

/**
 * Handler for common Caire theme style applications and font loading
 *
 * NextJS requires that global styles be imported in _app.tsx, and cannot be
 * refactored into a webapp-shared component. Therefore, you should also import the
 * following global stylesheets in your _app.tsx:
 *
 *     import '@shared-module-asset/normalize.css/normalize.css';
 *     import '@/style/global.css';
 *     import '@/style/theme.css';
 *
 */
const CaireStyle: FunctionComponent<CaireStyleProps> = ({
  children,
  theme = undefined,
  enableTenantTheme = theme ? false : true,
}) => {
  const { slug } = useTenantConfig();
  const themeName = theme || (enableTenantTheme && slug) || undefined;
  const themeClassName = `theme-${themeName}`;

  return (
    <>
      <CaireFont />
      <div
        className={cx('theme-root', {
          [themeClassName]: !!themeName,
        })}
        data-mantine-color-scheme="light"
        data-testid="theme-element">
        {children}
      </div>
    </>
  );
};

export default CaireStyle;
