import type { PageWithLayout } from '@/types/AppWithLayout';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const Home: PageWithLayout = () => {
  const { replace } = useRouter();
  useEffect(() => {
    replace('/auth/bypass');
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run once on mount
  }, []);

  return null;
};

export default Home;
