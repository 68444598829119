import { UUID } from '@/lib/graphql/scalars';

export enum HomepageWidget {
  FindCareCard = 'findCareCard',
  OtherWorkBenefits = 'otherWorkBenefits',
  PreventativeToDoList = 'preventativeToDoList',
  WelcomeWidget = 'welcomeWidget',
}

export interface HomepageWidgetConfig {
  readonly component: HomepageWidget;
  readonly props?: Record<string, unknown>;
}

export interface ProgramConfig {
  programId: UUID;
  programSlug: string;
  programName: string;
  contentfulTag: string;

  privacyPolicyUrl: string;
  termsOfServiceUrl: string;
  noticeOfPrivacyPracticesUrl: string;
  telehealthConsentUrl: string;
  welkinCloudLink: string;

  supportPhoneNumber: string;
  supportEmail: string;
  supportFaqUrl: string;

  homepage: HomepageWidgetConfig[];
}
