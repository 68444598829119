import type { ProgramConfig } from './types';
export type { ProgramConfig } from './types';
export { HomepageWidget } from './types';

import nwd from './nwd';
import providerSearch from './provider-search';
import upliv from './upliv';

export const allConfigs: Record<string, ProgramConfig> = {
  // Upliv first to ensure loaded before any other program with the same UUID.
  upliv,
  // New programs listed alphabetically.
  nwd,
  providerSearch,
};

export const programIdForSlug = (slug: string): string =>
  allConfigs[slug]?.programId ?? undefined;
