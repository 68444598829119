import { HomepageWidget, type ProgramConfig } from './types';

const providerSearch: ProgramConfig = {
  programName: 'Northwell Direct Provider Search (Legacy Config)',
  programId: '7da09a1e-a8b4-46b1-929b-4fa5470bc8ff',
  programSlug: 'provider-search',

  contentfulTag: 'platformUpliv',

  privacyPolicyUrl: 'https://www.uplivhealth.com/privacy-notice',
  termsOfServiceUrl: 'https://www.uplivhealth.com/terms-and-conditions',

  welkinCloudLink: 'https://care.live.welkincloud.io/signin',

  noticeOfPrivacyPracticesUrl:
    'https://www.uplivhealth.com/notice-of-privacy-practices',
  telehealthConsentUrl: 'https://www.uplivhealth.com/telehealth-consent',
  supportPhoneNumber: '6193771244',
  supportEmail: 'upliv-support@caire.health',
  supportFaqUrl: 'https://www.uplivhealth.com/faq',

  homepage: [
    {
      component: HomepageWidget.WelcomeWidget,
    },
    {
      component: HomepageWidget.FindCareCard,
    },
    {
      component: HomepageWidget.PreventativeToDoList,
    },
    {
      component: HomepageWidget.OtherWorkBenefits,
    },
  ],
};

export default providerSearch;
