import { Tooltip, UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import { forwardRef } from 'react';
import Icon, { IconProps } from '@/components/Icon';
import classes from './ActionIcon.system.module.css';
import cx from 'clsx';

export interface ActionIconProps extends Pick<UnstyledButtonProps, 'size'> {
  className?: string;
  disabled?: boolean;
  label?: string;
  name: IconProps['name'];
  tooltipDelay?: number;
  // onClick may be optional because some
  // compound components programatically injects
  // the handler from the parent, making it useless
  // to define a handler at the ActionIcon itself.
  onClick?: () => void;
  size?: 'sm' | 'md' | 'lg';
  type?: 'button' | 'submit' | 'reset';
}

const ActionIcon = forwardRef<HTMLButtonElement, ActionIconProps>(
  (
    {
      disabled,
      label,
      name,
      onClick,
      className,
      size = 'sm',
      type = 'button',
      tooltipDelay,
      ...otherButtonProps
    },
    ref
  ) => {
    const iconSize: IconProps['size'] = (() => {
      if (size === 'sm') {
        return 'xs';
      }

      if (size === 'md') {
        return 'sm';
      }

      return 'md';
    })();

    return (
      <Tooltip
        disabled={!label}
        label={label}
        position="bottom"
        openDelay={tooltipDelay}>
        <UnstyledButton
          aria-label={label}
          className={cx(classes.button, className)}
          data-size={size}
          disabled={disabled}
          onClick={onClick}
          ref={ref}
          type={type}
          {...otherButtonProps}>
          <Icon name={name} size={iconSize} />
        </UnstyledButton>
      </Tooltip>
    );
  }
);

ActionIcon.displayName = 'ActionIcon';

export default ActionIcon;
