/**
 * This file is used to extend the tenant configuration with static enhancements
 * before they are integrated into the server.
 *
 * IMPORTANT: Every extension here must be accompanies by a corresponding JIRA ticket
 * to track the real integration work.
 *
 * Configs are keyed by tenant slug for legibility, and loading syncronously in TenantConfig.
 */
import { TenantConfiguration } from '@/contexts/TenantConfig';

type TenantConfigExtension = Pick<
  TenantConfiguration,
  // CRE-3399 - Add enrollmentConfig to TenantConfig
  'enrollmentConfig'
>;

const caireUpliv: TenantConfigExtension = {
  enrollmentConfig: {
    steps: [
      'eligibility-check',
      'create-account',
      'personal-info',
      'postal-address',
    ],
  },
};

const northwellDirect: TenantConfigExtension = {
  enrollmentConfig: {
    steps: [
      'eligibility-check',
      'create-account',
      'personal-info',
      'postal-address',
    ],
  },
};

// Huntington Hospital is our test tenant
const huntington: TenantConfigExtension = {
  enrollmentConfig: {
    steps: [
      'eligibility-check',
      'create-account',
      'personal-info',
      'postal-address',
    ],
  },
};

const tenantConfigExtensions: Record<string, TenantConfigExtension> = {
  'caire-upliv': caireUpliv,
  'northwell-direct': northwellDirect,
  huntington,
} as const;

export default tenantConfigExtensions;
