import { HomepageWidget, type ProgramConfig } from './types';

const upliv: ProgramConfig = {
  programName: 'Huntington Hospital', // Overridden name for demo purposes
  programId: '6f3fd233-53f4-3e1d-b38e-3083917b67d8',
  programSlug: 'upliv',
  contentfulTag: 'platformUpliv',

  privacyPolicyUrl: 'https://www.uplivhealth.com/privacy-notice',
  termsOfServiceUrl: 'https://www.uplivhealth.com/terms-and-conditions',
  welkinCloudLink: 'https://care.live.welkincloud.io/signin',
  noticeOfPrivacyPracticesUrl:
    'https://www.uplivhealth.com/notice-of-privacy-practices',
  telehealthConsentUrl: 'https://www.uplivhealth.com/telehealth-consent',
  supportPhoneNumber: '6193771244',
  supportEmail: 'upliv-support@caire.health',
  supportFaqUrl: 'https://www.uplivhealth.com/faq',

  homepage: [
    {
      component: HomepageWidget.WelcomeWidget,
    },
    {
      component: HomepageWidget.FindCareCard,
    },
    {
      component: HomepageWidget.PreventativeToDoList,
    },
    {
      component: HomepageWidget.OtherWorkBenefits,
    },
  ],
};

export default upliv;
