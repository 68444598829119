import { useEffect, type FunctionComponent } from 'react';
import type { DefaultPrivacyLevel } from '@datadog/browser-rum-slim';

export interface RealUserMetricsProps {
  applicationId: string;
  clientToken: string;
  serviceName: string;
  environment: string;
  tracingUrls?: string[];
  options?: {
    version?: string;
    sampleRate?: number;
    replaySampleRate?: number;
    trackUserInteractions?: boolean;
    trackResources?: boolean;
    trackLongTasks?: boolean;
    defaultPrivacyLevel?: DefaultPrivacyLevel;
  };
}

const RealUserMetrics: FunctionComponent<RealUserMetricsProps> = ({
  applicationId,
  clientToken,
  serviceName,
  environment,
  tracingUrls = [],
  options,
}) => {
  useEffect(() => {
    // @datadog/browser-rum is a huge dependency, so we lazy load it:
    import('@datadog/browser-rum-slim').then(
      ({ datadogRum, DefaultPrivacyLevel }) => {
        const fullOptions = {
          sampleRate: 100,
          replaySampleRate: 20,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
          ...options,
          ...(tracingUrls.length > 0
            ? { allowedTracingUrls: tracingUrls }
            : {}),
        };

        datadogRum.init({
          applicationId: applicationId,
          clientToken: clientToken,
          site: 'datadoghq.com',
          service: serviceName,
          version: process.env.GIT_SHA,
          env: environment,
          ...fullOptions,
        });
      }
    );
  }, [
    applicationId,
    clientToken,
    environment,
    options,
    serviceName,
    tracingUrls,
  ]);

  return null;
};

export default RealUserMetrics;
